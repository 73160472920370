import { USER_LOADING } from "../actionTypes";
import axios from "axios";

let URL=process.env.REACT_APP_URL
let NewURL = process.env.REACT_APP_NewURL




export const managerToken = (data) => (dispatch) => {
 
  dispatch({ type: USER_LOADING });
  return axios.post(
    `${process.env.REACT_APP_NewURL}web-app/manager/generate-token?userId=${data.userId}&password=${data.password}&managerRole=${data.userRole}`
  );
};


export const dealerToken = (data) => (dispatch) => {
 
    dispatch({ type: USER_LOADING });
    return axios.post(
      `${NewURL}web-app/dealer/generate-token?userId=${data.userId}&password=${data.password}`
    );
  };
// Action for OTP verification
export const otpVarificationManager = (data, authToken) => (dispatch) => {
  dispatch({ type: USER_LOADING }); // Dispatch loading state

  return axios.post(
    `${NewURL}web-app/manager/verify-otp?otp=${data.otp}&managerRole=${data.mangerRole}`, 
    {}, // Pass an empty object for the body
    {
      headers: {
        Authorization: `Bearer ${authToken}`, // Pass Bearer token for authentication
      },
    }
  );
};



export const otpVarificationDealer = (otp, authToken) => (dispatch) => {
  dispatch({ type: USER_LOADING }); // Dispatch loading state

  // Make API call to verify the OTP
  return axios.post(
    `${NewURL}web-app/dealer/verify-otp?otp=${otp}`, 
    {}, // Pass an empty object for the body
    {
      headers: {
        Authorization: `Bearer ${authToken}`, // Pass Bearer token for authentication
      },
    }
  );
};

