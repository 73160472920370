import axios from "axios";
import {
  GET_ALGO_REQUEST,
  GET_ALGO_SUCCESS,
  GET_ALGO_FAILURE,
  GET_SIGNAL_REQUEST,
  GET_SIGNAL_SUCCESS,
  GET_SIGNAL_FAILURE,
} from "../actionTypes";


let URL=process.env.REACT_APP_ADMIN_API



// Fetch algo data
export const fetchAlgo = (token) => async (dispatch) => {
  dispatch({ type: GET_ALGO_REQUEST });
  try {
    const response = await axios.get(`${URL}/get-list/baskets`, {
      headers: { "Access-Token": token },
    });
   
    dispatch({ type: GET_ALGO_SUCCESS, payload: response.data.response.data.reverse() });
  } catch (error) {
    dispatch({ type: GET_ALGO_FAILURE, payload: error.message });
  }
};

// Fetch signal data
export const fetchSignals = (token) => async (dispatch) => {
  dispatch({ type: GET_SIGNAL_REQUEST });
  try {
    const response = await axios.get(`${URL}/get-list/baskets`, {
      headers: { "Access-Token": token },
    });
    dispatch({ type: GET_SIGNAL_SUCCESS, payload: response.data.response.data.reverse()  });
  } catch (error) {
    dispatch({ type: GET_SIGNAL_FAILURE, payload: error.message });
  }
};

