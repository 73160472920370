import React, { useState, useRef, useCallback, useMemo } from "react";
import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  List,
  ListItem,
  Text,
} from "@chakra-ui/react";
import { MdArrowDropDown } from "react-icons/md";
import { SearchIcon } from "@chakra-ui/icons";
import { FixedSizeList } from "react-window"; // Importing for virtualization

const ScriptDropdown = React.memo(({ symbols, onSelect }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSymbol, setSelectedSymbol] = useState(null); // State to store selected symbol
  const dropdownRef = useRef(null);

  const filteredSymbols = useMemo(() => {
    if (!searchTerm) return symbols;
  
    const searchTermLower = searchTerm.toLowerCase();
  
    // First, filter by name
    let results = symbols.filter((item) =>
      item.instrument.toLowerCase().includes(searchTermLower)
    );
  
    // If no results by name, filter by instrument
    if (results.length === 0) {
      results = symbols.filter((item) =>
        item.name.toLowerCase().includes(searchTermLower)
      );
    }
  
    return results;
  }, [symbols, searchTerm]);
  

  const handleSearchInputChange = (value) => {
    setSearchTerm(value);
  };

  const handleDropdownToggle = useCallback(() => {
    setShowOptions((prev) => !prev);
  }, []);

  const handleSymbolSelect = (item) => {
    setSelectedSymbol(item); // Update the selected symbol
    setShowOptions(false); // Close dropdown on select
    onSelect(item); // Pass selected item to parent
  };

  const Row = ({ index, style }) => {
    const item = filteredSymbols[index];
    return (
      <ListItem
        key={item.symbol}
        style={style}
        onClick={() => handleSymbolSelect(item)}
        cursor="pointer"
        _hover={{ background: "gray.100" }}
        px={4}
        py={2}
        borderRadius="md"
        bg="gray.50"
        mb={1}
      >
        {item.name}
      </ListItem>
    );
  };

  return (
    <Box position="relative" ref={dropdownRef}>
      <InputGroup>
        <Input
          value={selectedSymbol ? selectedSymbol.name : ""} // Display selected symbol name
          placeholder="Select Script"
          variant="filled"
          bg="gray.100"
          _focus={{ bg: "white", borderColor: "gray.300" }}
          readOnly
          cursor="pointer"
          onClick={handleDropdownToggle}
        />
        <InputRightElement width="2.5rem">
          <IconButton
            aria-label="Dropdown icon"
            icon={<MdArrowDropDown />}
            variant="ghost"
            onClick={handleDropdownToggle}
          />
        </InputRightElement>
      </InputGroup>

      {showOptions && (
        <Box
          position="absolute"
          top="100%"
          left={0}
          right={0}
          bg="white"
          boxShadow="lg"
          zIndex={10}
          maxHeight="200px"
          overflow="hidden"
          borderRadius="md"
          mt={1}
          p={2}
        >
          <InputGroup mb={2}>
            <Input
              value={searchTerm}
              onChange={(e) => handleSearchInputChange(e.target.value)}
              placeholder="Search Scripts"
              bg="gray.50"
              border="1px"
              borderColor="gray.300"
              _focus={{ bg: "white", borderColor: "blue.500" }}
            />
            <InputRightElement width="2.5rem">
              <IconButton
                aria-label="Clear search"
                icon={<SearchIcon color="gray.500" />}
                variant="ghost"
                onClick={() => setSearchTerm("")}
              />
            </InputRightElement>
          </InputGroup>

          {filteredSymbols.length ? (
            <List spacing={1}>
               <FixedSizeList
                height={150}
                itemCount={filteredSymbols.length}
                itemSize={40} // Adjust item size based on your design
                width="100%"
              >
                {Row}
              </FixedSizeList> 
            </List>
          ) : (
            <Text px={4} py={2} color="gray.500">
              No options found
            </Text>
          )}
        </Box>
      )}
    </Box>
  );
});

export default ScriptDropdown;
