import axios from 'axios';
import { CLIENTS_REQUEST, GET_CLIENTS_SUCCESS, CLIENTS_FAILURE } from '../actionTypes';

export const clientsRequest = () => ({
  type: CLIENTS_REQUEST,
});

export const getClientsSuccess = (payload) => ({
  type: GET_CLIENTS_SUCCESS,
  payload,
});

export const clientsFailure = (error) => ({
  type: CLIENTS_FAILURE,
  payload: error,
});


let url=process.env.REACT_APP_NewURL

export const fetchClients = (token) => (dispatch) => {
  dispatch(clientsRequest());

  axios.get(`${url}web-app/dealer/dealers-client-list`, {
    headers: {
      Authorization: `Bearer ${token}`, // Pass Bearer token for authentication
    },
  })
  .then((response) => {
 
  if(response.data.status==="success"){

    dispatch(getClientsSuccess(response.data.data.clients));
  }
  })
  .catch((error) => {
    console.error('Error fetching clients:', error);
    dispatch(clientsFailure(error.message));
  });
};

export const sendMessageToClient = (dataToSend, token) => async () => {
  
  try {
    const response = await axios.post(`${url}web-app/dealer/send-message-clients?clientId=${dataToSend.clientId}&basketId=${dataToSend.basketId}&messageType=${dataToSend.messageType}`, {}, {
      headers: {
        Authorization: `Bearer ${token}`, // Pass Bearer token for authentication
      },
    });
 
    // fetchBasketClientList(dataToSend.basketId,token)
    // fetchClients(token)
    return response.data;
  } catch (error) {
    console.log("Error in postAddToBasket:", error);
    throw error; // Ensure the error is thrown so it can be caught in handleAddToBasket
  }
};


export const fetchAllClientLists = (token) => (dispatch) => {

  return axios.get(`${url}app/client/clients-list`, {
    headers: {
      Authorization: `Bearer ${token}`, // Pass Bearer token for authentication
    },
  });
};

// <=============================Admin================================>

export const fetchAllClients = (token) => (dispatch) => {
  dispatch(clientsRequest());

  axios.get(`${url}get-list/client-profile`, {
    headers: { "Access-Token": token },
  })
  .then((response) => {
  
    dispatch(getClientsSuccess(response.data.response.data));
  })
  .catch((error) => {
    
    dispatch(clientsFailure(error.message));
  });
};


export const fetchAllBasketClientList = (basket_id, token) => (dispatch) => {

  return axios.get(`${url}basket-client-list/admin/baskets?basket_id=${basket_id}`, {
    headers: { "Access-Token": token },
  });
};